// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "./node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "./node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "./node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/CONSOLA.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/consolai.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Consolas.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/consolaz.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n    font-family: 'consolas';\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), \r\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n", "",{"version":3,"sources":["webpack://./index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB;oJACsG;IACtG,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["@font-face {\r\n    font-family: 'consolas';\r\n    src: url(./fonts/CONSOLA.TTF), url(./fonts/CONSOLA.TTF), \r\n        url(./fonts/consolai.ttf), url(./fonts/Consolas.ttf), url(./fonts/consolaz.ttf) format('truetype');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
